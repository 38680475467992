import { createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_BASE_URL } from './api';
import  axios  from './api';

// import { axios, BASE_URL } from './apiConfig';



export const fetchCreviews = createAsyncThunk('creviews/fetchCreviews', async (thunkAPI) => {
  // const response = await axios.get(`${BASE_URL}/api/creviews/${{}}`);
  // return response.data;

  try {
    const response = await axios.get(`api/creviews`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const fetchCreviewsByWork = createAsyncThunk('creviews/fetchCreviewsByWork', async ({workId},thunkAPI) => {
  // const response = await axios.get(`${BASE_URL}/api/creviews/${{}}`);
  // return response.data;

  try {
    const response = await axios.get(`api/creviews/listcreviewcreatedby/${workId}`);

    
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const addCreview = createAsyncThunk('creviews/addCreview', async (creview) => {
  const response = await axios.post(`api/creviews/creview`,creview);
  return response.data;

 // try {
    // const formData = new FormData();
    // formData.append('creview',creview);
   // formData.append('file', file);
   // const response = await axios.post(`${BASE_URL}/api/creviews`, creview 
    //   {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   },
    // }
  // ) 
  //   .then(response => {
  //     console.log('Success:', response.data);
  // })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
    // const data = response.data;
    // return data;    

// } catch (error) {
//     return thunkAPI.rejectWithValue(error.message);
// }
});

export const updateCreview = createAsyncThunk('creviews/updateCreview', async ({ creviewId, creview }) => {
  const response = await axios.put(`api/creviews/${creviewId}`, creview);
  return response.data;
});

export const deleteCreview = createAsyncThunk('creviews/deleteCreview', async (creviewId) => {
  await axios.delete(`api/creviews/${creviewId}`);
  return creviewId;
}); 

export const changeCreviewStatus = createAsyncThunk('creviews/changeCreviewStatus', async ({ creviewId, active }) => {
  const response = await axios.patch(`api/creviews/${creviewId}/status?active=${active}`);
  return response.data;
});
const creviewsSlice = createSlice({
  name: 'creviews',
  initialState: {
    creviews: [],
    status: 'idle',
    error: null,
    owner:null,
    listcreviewcreatedby:[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreviews.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCreviews.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.creviews = action.payload;
       
      })
      .addCase(fetchCreviewsByWork.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listcreviewcreatedby = action.payload;
       
      })
      .addCase(fetchCreviews.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addCreview.fulfilled, (state, action) => {
        state.creviews.push(action.payload);
      })
      .addCase(updateCreview.fulfilled, (state, action) => {
        const index = state.creviews.findIndex(creview => creview.creviewId === action.payload.creviewId);
        state.creviews[index] = action.payload;
      })
      .addCase(deleteCreview.fulfilled, (state, action) => {
        state.creviews = state.creviews.filter(creview => creview.creviewId !== action.payload);
      })

      .addCase(changeCreviewStatus.fulfilled, (state, action) => {
        const index = state.creviews.findIndex((creview) => creview.creviewId === action.payload.creviewId);
        state.creviews[index] = action.payload;
    })
  },
});

export default creviewsSlice.reducer;