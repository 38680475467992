import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from './api';
// import axios from 'axios';


// import { fetchAdminapps, addAdminapp, updateAdminapp, deleteAdminapp } from './actions';

export const fetchAdminapps = createAsyncThunk('adminapps/fetchAdminapps', async () => {
  const response = await axios.get(`api/adminapps`);
  return response.data;
});

export const addAdminapp = createAsyncThunk('adminapps/addAdminapp', async (adminapp) => {
  const response = await axios.post(`api/adminapps`, adminapp);
  return response.data;
});

export const updateAdminapp = createAsyncThunk('adminapps/updateAdminapp', async ({ id, adminapp }) => {
  const response = await axios.put(`api/adminapps/${id}`, adminapp);
  return response.data;
});

export const deleteAdminapp = createAsyncThunk('adminapps/deleteAdminapp', async (id) => {
  await axios.delete(`api/adminapps/${id}`);
  return id;
}); 

export const changeAdminappStatus = createAsyncThunk('adminapps/changeOrgutpStatus', async ({ id, active }) => {
  const response = await axios.patch(`api/adminapps/${id}/status?active=${active}`);
  return response.data;
});
const adminappsSlice = createSlice({
  name: 'adminapps',
  initialState: {
    adminapps: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminapps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminapps.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.adminapps = action.payload;
      })
      .addCase(fetchAdminapps.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addAdminapp.fulfilled, (state, action) => {
        state.adminapps.push(action.payload);
      })
      .addCase(updateAdminapp.fulfilled, (state, action) => {
        const index = state.adminapps.findIndex(item => item.id === action.payload.id);
        state.adminapps[index] = action.payload;
      })
      .addCase(deleteAdminapp.fulfilled, (state, action) => {
        state.adminapps = state.adminapps.filter(item => item.id !== action.payload);
      })

      .addCase(changeAdminappStatus.fulfilled, (state, action) => {
        const index = state.orgutps.findIndex((adminapp) => adminapp.id === action.payload.id);
        state.adminapps[index] = action.payload;
    });
  },
});

export default adminappsSlice.reducer;