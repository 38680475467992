import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from './api';

// Async actions for CRUD operations
export const fetchSvisits = createAsyncThunk('svisits/fetchSvisits', async () => {
    const response = await axios.get(`api/svisits`);
    return response.data;
});
export const fetchImages = createAsyncThunk('svisits/fetchImages', async () => {
    const response = await axios.get(`api/svisits/simages`);
    return response.data;
});

export const createSvisit = createAsyncThunk('svisits/createSvisit', async (svisitData) => {
    const response = await axios.post(`api/svisits`, svisitData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
});

export const updateSvisit = createAsyncThunk('svisits/updateSvisit', async ({ id, svisitData }) => {
    const response = await axios.put(`api/svisits/${id}`, svisitData);
    return response.data;
});

export const deleteSvisit = createAsyncThunk('svisits/deleteSvisit', async (id) => {
    await axios.delete(`api/svisits/${id}`);
    return id;
});

const svisitSlice = createSlice({
    name: 'svisits',
    initialState: {
        svisits: [],
        loading: false,
        error: null,
        simages:[],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSvisits.fulfilled, (state, action) => {
                state.svisits = action.payload;
            })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.simages = action.payload;
            })
            .addCase(createSvisit.fulfilled, (state, action) => {
                state.svisits.push(action.payload);
            })
            .addCase(updateSvisit.fulfilled, (state, action) => {
                const index = state.svisits.findIndex(user => user.id === action.payload.id);
                state.svisits[index] = action.payload;
            })
            .addCase(deleteSvisit.fulfilled, (state, action) => {
                state.svisits = state.svisits.filter(user => user.id !== action.payload);
            });
    },
});

export default svisitSlice.reducer;