import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPerson, fetchPersons } from "../../Redux/personSlice";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2 as Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { fetchRdesigs } from "../../Redux/rdesigSlice";

const PersonForm = () => {

  const employeesByIdName=[
    {
    "id":1,
    "name":"software developer"
  },
  {
    "id":2,
    "name":"software support"
  }
]
  const { auth } = useSelector((store) => store);
  const [pino, setPino] = useState("");
  const [pidate, setPidate] = useState("");

  const [proposalno, setProposalno] = useState("");
  const [dow, setDow] = useState("");
  const [doj, setDoj] = useState("");
  const [durationpi, setDurationpi] = useState("");

  const [efileno, setEfileno] = useState("");
  const [noresource, setNoresource] = useState("");
  const [estamt, setEstamt] = useState("");
  // const [rdesig, setRdesig] = useState("");
  const [rpost, setRpost] = useState("");
  const [advpaymentquaterly, setAdvpaymentquaterly] = useState(false);
  const [advpaymentquaterlyAmt, setAdvpaymentquaterlyAmt] = useState("");
  const [rtgsdate, setRtgsdate] = useState("");
  const [rtgsno, setRtgsno] = useState("");
  const [noteno, setNoteno] = useState("");
  const [taxInvoicefromHatron, setTaxInvoicefromHatron] = useState(false);
  const [active, setActive] = useState(false);

  const [createdBy, setCreatedBy] = useState(`${auth.user.email}`);
  const [remark, setRemark] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  const [selectedValues, setSelectedValues] = useState([]);

  // Options for the Select component
  const options = ['Software Developer', 'Software Support', 'Option 3', 'Option 4'];

  // Handle change event
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValues(value);
  };

  const persons = useSelector((state) => {return state.persons.persons;
  });
  const { rdesigs } = useSelector(state => state.rdesigs);
  const handleSubmit = (e) => {
    console.log("welcome");
    e.preventDefault();
    // console.log("welcome welcome");
    if (
      pino &&
      pidate &&
      proposalno &&
      dow &&
      doj &&
      durationpi &&
      efileno &&
      noresource &&
      estamt &&
      selectedValues &&
    //  rdesig &&
      rpost &&
      advpaymentquaterly &&
      advpaymentquaterlyAmt &&
      rtgsno &&
      rtgsdate &&
      noteno &&
      taxInvoicefromHatron &&
      active &&
      createdBy &&
      remark &&
      file
    ) {
      dispatch(
        createPerson({
          pino,
          pidate,
          proposalno,
          dow,
          doj,
          durationpi,
          efileno,
          noresource,
          estamt,
          selectedValues,
          //  rdesig,
          rpost,
          advpaymentquaterly,
          advpaymentquaterlyAmt,
          rtgsno,
          rtgsdate,
          noteno,
          taxInvoicefromHatron,
          active,
          createdBy,
          remark,
          file,
        })
      );

      dispatch(fetchPersons());
      dispatch(fetchRdesigs());
      setPino("");
      setPidate("");
      setProposalno("");
      setDow("");
      setDoj("");
      setDurationpi("");
      setEfileno("");
      setNoresource("");
      setEstamt("");
      setSelectedValues("");
      // setRdesig("");
      setRpost("");
      setAdvpaymentquaterly("");
      setAdvpaymentquaterlyAmt("");
      setRtgsdate("");
      setRtgsno("");
      setNoteno("");
      setTaxInvoicefromHatron("");
      setActive("");
      setRemark("");
      setFile(null);
    }
  };

  return (
    <>
      <Typography variant="h6" align="center">
        Invoice.. <hr />
      </Typography>
      <Grid container spacing={2} align="center">
        <Grid size={12}>
          <form onSubmit={handleSubmit}>
            <Grid
              item
              container
              direction="column"
              size={{ xs: 3, sm: 4, md: 6, lg: 12 }}
              spacing={2}
            >
              <Stack direction={"row"} spacing={2} mt={2}>
                <Grid item size={4}>
                  <TextField
                    fullWidth
                    label="Project Name"
                    multiline
                    rows={2}
                    value={rpost}
                    onChange={(e) => setRpost(e.target.value)}
                    //   margin="normal"
                  />
                </Grid>

                <Grid size={4}>
                  <TextField
                    fullWidth
                    label="Proposal No."
                    value={proposalno}
                    onChange={(e) => setProposalno(e.target.value)}
                    required
                  />
                </Grid>

                <Grid size={4}>
                  <TextField
                    fullWidth
                    label="Date of Work"
                    type="date"
                    value={dow}
                    onChange={(e) => setDow(e.target.value)}
                    required
                  />
                </Grid>
                <Grid size={4}>
                  <TextField
                    fullWidth
                    label="Date of Start"
                    type="date"
                    value={doj}
                    onChange={(e) => setDoj(e.target.value)}
                    required
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid
              item
              container
              direction="column"
              size={{ xs: 3, sm: 4, md: 6, lg: 12 }}
              spacing={2}
            >
              <Stack direction={"row"} spacing={2} mt={2}>
                <Grid item size={4}>
                  <TextField
                    fullWidth
                    label="No. of Resouces"
                    type="number"
                    rows={2}
                    value={noresource}
                    onChange={(e) => setNoresource(e.target.value)}
                    //   margin="normal"
                  />
                </Grid>

                <Grid item size={4}>

                {/* <FormControl fullWidth>
      <InputLabel id="multiple-select-label">Select Options</InputLabel>
      <Select
        labelId="multiple-select-label"
        id="multiple-select"
        multiple
        value={selectedValues}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')} 
      >
        {rdesigs.map((option) => (
          <MenuItem key={option.rid} value={option.rdname}>
            <Checkbox checked={selectedValues.indexOf(option.rid) > -1} />
            <ListItemText primary={option.rdname} />
          </MenuItem>
        ))}
      </Select>
    </FormControl> */}

                {/* <InputLabel id="rdesig">Select Resource Designation</InputLabel>
<Select
                multiple
                value={selectedEmployees}
                onChange={e => setSelectedEmployees(e.target.value)}
                style={{width:400}}
            >
                {employeesByIdName?.map(emp => (
                    <MenuItem 
                     key={emp.id} value={emp.id} 
                    sx={{width:400}}>
                        {emp.name}

                      
                    </MenuItem>
                ))} 
            </Select> */}
                  {/* <TextField
                    fullWidth
                    label="Resource Designation"
                    rows={2}
                    value={rdesig}
                    onChange={(e) => setRdesig(e.target.value)}                  
                  /> */}
                </Grid>

                <Grid size={4}>
                  <TextField
                    fullWidth
                    label="Pi No."
                    rows={2}
                    value={pino}
                    onChange={(e) => setPino(e.target.value)}
                    //   margin="normal"
                  />
                </Grid>

                <Grid size={4}>
                  <TextField
                    fullWidth
                    label="PI Date"
                    type="date"
                    value={pidate}
                    onChange={(e) => setPidate(e.target.value)}
                    required
                  />
                </Grid>
                <Grid size={4}>
                  <TextField
                    fullWidth
                    label="PI Durartion"
                    type="date"
                    value={durationpi}
                    onChange={(e) => setDurationpi(e.target.value)}
                    required
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid
              item
              container
              direction="column"
              size={{ xs: 3, sm: 4, md: 6, lg: 12 }}
              spacing={2}
            >
              <Stack direction={"row"} spacing={2} mt={2}>
                <Grid item size={4}>
                  <TextField
                    fullWidth
                    label="Amount After Deduction"
                    rows={2}
                    value={estamt}
                    onChange={(e) => setEstamt(e.target.value)}
                    //   margin="normal"
                  />
                </Grid>

                <Grid item size={4}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Advance Payment Quarterly
                    </FormLabel>
                    <Stack direction={"row"}>
                      {" "}
                      <RadioGroup
                        row
                        aria-label="advpaymentquaterly"
                        name="taxInvoicefromHatron"
                        value={advpaymentquaterly}
                        onChange={(e) => setAdvpaymentquaterly(e.target.value)}
                        defaultValue="yes"
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="YES"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    </Stack>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  size={{ xs: 3, sm: 4, md: 6, lg: 12 }}
                  spacing={2}
                >
                  <Stack direction={"row"} spacing={2} mt={2}>
                    <Grid item size={4}>
                      <TextField
                        fullWidth
                        label="Advance Payment Quarterly"
                        type="number"
                        rows={2}
                        value={advpaymentquaterlyAmt}
                        onChange={(e) =>
                          setAdvpaymentquaterlyAmt(e.target.value)
                        }
                        // margin="normal"
                      />
                    </Grid>
                    <Grid item size={4}>
                      <TextField
                        fullWidth
                        label="RTGS No."
                        rows={2}
                        value={rtgsno}
                        onChange={(e) => setRtgsno(e.target.value)}
                      />
                      <TextField
                        // fullWidth
                        // label="RTGS No."
                        type="hidden"
                        // rows={2}
                        value={createdBy}
                        onChange={(e) => setCreatedBy(e.target.value)}
                      />
                    </Grid>
                    
                  </Stack>
                </Grid>
              </Stack>


            </Grid>

<Grid
              item
              container
              direction="column"
              size={{ xs: 3, sm: 4, md: 6, lg: 12 }}
              spacing={2}
            >
            <Stack direction={"row"} spacing={2} mt={2}>

              <Grid
                item
                container
                direction="column"
                size={{ xs: 3, sm: 4, md: 6, lg: 12 }}
                spacing={2}
              >
                <Stack direction={"row"} spacing={2} mt={2}>

                <Grid item size={4}>
                      <TextField
                        fullWidth
                        label="RTGS Date"
                        type="date"
                        rows={2}
                        value={rtgsdate}
                        onChange={(e) => setRtgsdate(e.target.value)}
                        //   margin="normal"
                      />
                    </Grid>
                  <Grid item size={4}>
                    <TextField
                      fullWidth
                      label="File No."
                      rows={2}
                      value={efileno}
                      onChange={(e) => setEfileno(e.target.value)}
                      //   margin="normal"
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      fullWidth
                      label="Note No."
                      rows={2}
                      value={noteno}
                      onChange={(e) => setNoteno(e.target.value)}
                      //   margin="normal"
                    />
                  </Grid>

                  <Grid item size={4}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Tax Invoice From Hartron
                      </FormLabel>
                      <Stack direction={"row"}>
                        {" "}
                        <RadioGroup
                          row
                          aria-label="taxInvoicefromHatron"
                          name="taxInvoicefromHatron"
                          value={taxInvoicefromHatron}
                          onChange={(e) =>
                            setTaxInvoicefromHatron(e.target.value)
                          }
                          defaultValue="yes"
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="YES"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Stack>
                    </FormControl>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item size={4}>
                {" "}
                <FormControlLabel
                  control={
                    <Switch
                      fullWidth
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </Grid>
              </Stack>
              </Grid>
              {/* <Grid
                item
                container
                direction="column"
                size={{ xs: 3, sm: 4, md: 6, lg: 12 }}
                spacing={2}
              > */}

<Grid
              item
              container
              direction="column"
              size={{ xs: 3, sm: 4, md: 6, lg: 12 }}
              spacing={2}
            >
                <Stack direction={"row"} spacing={2} mt={2}>
                  <Box height={10} />
                  <Grid size={4}>
                    <TextField
                      fullWidth
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      required
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      fullWidth
                      label="Remark"
                      // type="date"
                      rows={2}
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      //   margin="normal"
                    />
                  </Grid>
                </Stack>
              {/* </Grid> */}
           
            <Box height={10} />
            <Button variant="contained" type="submit">
              Submit
            </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonForm;
