import newAxios from 'axios';

  //  export const BASE_URL="http://localhost:8080"
  export const BASE_URL = `http://api.erpfmda.in`;

//export const BASE_URL="http://172.16.4.150:8080"
const axios=newAxios.create({
    baseURL:BASE_URL
    }) 


    

// export const jwt= document.cookie.match('(^|;)\\s*jwt\\s*=\\s*([^;]+)')?.pop() || ''

export const setCookie=(cname, cvalue, hours)=> {
    const d = new Date();
    d.setTime(d.getTime() + (hours * 60 * 60 * 1000));
    const expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  export const getCookie=(key)=>document.cookie.match('(^|;)\\s*'+key+'\\s*=\\s*([^;]+)')?.pop() || ''

  axios.interceptors.request.use(config => {
    const authToken = getCookie('jwt');

      config.headers={
        ...config.headers,
        ...(authToken && {
            'Authorization':`Bearer ${authToken}`
        })
      }
    
    return config;
  });

  axios.interceptors.response.use(
    (response)=>response,
    (error)=>{
        if(error.response && error.response.status===401){
            console.log('call the refresh token api here Unathorised');
            
        }
        return Promise.reject(error)
    }
  );



  export {axios} ;