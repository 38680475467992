import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from './api';

// Async actions for CRUD operations
export const fetchPerformainvoices = createAsyncThunk('performainvoices/fetchPerformainvoices', async () => {
    const response = await axios.get(`api/performainvoices`);
    //console.log(response.data);
    
    return response.data;
});
export const fetchImages = createAsyncThunk('performainvoices/fetchImages', async () => {
    const response = await axios.get(`api/performainvoices/images`);
    return response.data;
    //console.log(response.data);
    
});

export const createPerformainvoice = createAsyncThunk('performainvoices/createPerformainvoice', async (invoiceData) => {
    const response = await axios.post(`api/performainvoices`, invoiceData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
});

export const updatePerformainvoice = createAsyncThunk('performainvoices/eupdatePerformainvoice', async ({ id, performainvoicesData }) => {
    const response = await axios.put(`api/performainvoices/${id}`, performainvoicesData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
});

export const deletePerformainvoice = createAsyncThunk('performainvoices/deletePerformainvoice', async (id) => {
    await axios.delete(`api/performainvoices/${id}`);
    return id;
});

const invoiceSlice = createSlice({
    name: 'performainvoices',
    initialState: {
        performainvoices: [],
        loading: false,
        error: null,
        perimages:[],
       
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPerformainvoices.fulfilled, (state, action) => {
                state.performainvoices = action.payload;
            })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.perimages = action.payload;
            })
            .addCase(createPerformainvoice.fulfilled, (state, action) => {
                state.performainvoices.push(action.payload);
            })
            .addCase(updatePerformainvoice.fulfilled, (state, action) => {
                const index = state.performainvoices.findIndex(performainvoice => performainvoice.id === action.payload.id);
                state.performainvoices[index] = action.payload;
            })
            .addCase(deletePerformainvoice.fulfilled, (state, action) => {
                state.performainvoices = state.performainvoices.filter(performainvoice => performainvoice.id !== action.payload);
            });
    },
});

export default invoiceSlice.reducer;