import React, { useEffect } from "react";
import WorkList from "../../Xen/components/WorkList";
import { BASE_URL } from "../../Redux/apiConfig";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid2 as Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorks } from "../../Redux/workReducer";
import {
  DataGrid,
  DEFAULT_GRID_AUTOSIZE_OPTIONS,
  GridToolbar,
} from "@mui/x-data-grid";
import Sidenav from "./Sidenav";
import { color } from "highcharts";

const ProjectList = () => {
  const works = useSelector((state) => state.works.works);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWorks());
  }, [dispatch]);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "index",
      headerName: "Sr. No.",
      // filterable: true,
      // sortable: true,
      renderCell: (index) =>
        index.api.getRowIndexRelativeToVisibleRows(index.row.id) + 1,
    },
    ,
    {
      field: "workname",
      headerName: "Work Name",
      width: 500,
      renderCell: (params) => (
        <div
          style={{
            textAlign: "justify", // Inline style for justification
            whiteSpace: "normal", // Allow text wrapping
            wordBreak: "break-word", // Break long words
          }}
        >
          {params.value}
        </div>
      ),
    },
    { field: "fileno", headerName: "File No." },
    { field: "aramt", headerName: "Approved Amount" },
    {
      field: "vender",
      headerName: "Agency Name",
      renderCell: (params) => {
        return params?.row?.vender?.vendername;
      },
    },
    // { field: "agencyName", headerName: "Agency Name"},
    {
      field: "startDate",
      headerName: "Start Date",
      valueFormatter:
        //  ({params}) => {
        // (params) => console.log(params.split('-').reverse().join('-'))
        (params) =>
          new Date(params).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "2-digit",
            year: "numeric",
          }),
    },
    { field: "timeLimit", headerName: "Time Limit" },
    { field: "dlp", headerName: "DLP" },
    {
      field: "sdDateComplition",
      headerName: "Date of Complition",
      valueFormatter:
        //  ({params}) => {
        // (params) => console.log(params.split('-').reverse().join('-'))
        (params) =>
          new Date(params).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "2-digit",
            year: "numeric",
          }),
    },
    {
      field: "srDateComplition",
      headerName: "Rivised Date of Complition",
      valueFormatter:
        //  ({params}) => {
        // (params) => console.log(params.split('-').reverse().join('-'))
        (params) =>
          new Date(params).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "2-digit",
            year: "numeric",
          }),
    },
    { field: "budgetDYear", headerName: "Budget During Year" },
    { field: "phyStatus", headerName: "Physical Work Status(%) " },
    { field: "finStatus", headerName: "Finacial Work Status(%)" },
    { field: "wstatus", headerName: "Project/Work States" },
    {
      field: "created",
      headerName: "Created Date",
      valueFormatter:
        //  ({params}) => {
        // (params) => console.log(params.split('-').reverse().join('-'))
        (params) =>
          new Date(params)
            .toLocaleDateString("en-GB", {
              // weekday: 'short',

              day: "numeric",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })
            .toUpperCase()
            .replaceAll(["/"], "-"),
    },
    // { field: "org", headerName: "Division" },
    {
      field: "employees",
      headerName: "Project Monitor By",
      width: 500,

      renderCell: (params) => {
        return params?.row?.employees?.map((r) => r.name).join(", ");
      },
    },


// Contractor Work Reports
{
  field: "ctvisits",
  headerName: "Contractor Work Report",
  width: 500,
  renderCell: (params) => {
    return (
     <>
     {params?.row?.ctvisits?.map((row) => (
      <Card sx={{ maxWidth: 'auto' }}>
      <CardActionArea>       
        <CardContent>
        <Typography gutterBottom variant="h5" component="div">
            As On :{new Intl.DateTimeFormat("en-GB", {
                    day: "2-digit",
                    year: "numeric",
                    month: "short",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true,
                  }).format(new Date(row.created))}:
          </Typography>
          <Stack direction={row}>No. Labour:  {row.nol} No. Days: {row.nod} <br/>
          Start Date:{row.sdate} End Date:{row.edate}</Stack>
          
          <Typography variant="h6" color="blue" align="justify">{row.review}</Typography>
          <Typography variant="body2" align="right" sx={{ color: 'text.secondary' }}>
          Created By:{row.createdBy}
          </Typography>
        </CardContent>
        {row?.ctimages?.map((r) => (<>
        
          <a 
                          color="green"
                          target="_blank"
                          href={`${BASE_URL}/ctvisits/image/${r.ctimageName}`}
                        >
      <Stack direction={row}>  <CardMedia
        
          component="img"
          height={20}
          width={20}
          key={r.id}
          image={`${BASE_URL}/ctvisits/image/${r.ctimageName}`}
          alt="green iguana"
        /></Stack>
       
                         
                        </a>
                      
       </>
        
        ))}
      </CardActionArea>
      
    </Card>
     ))}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 150 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold", fontSize: "20" }}>
                Nature of work type
              </TableCell>
              <TableCell align="right">No. Labour</TableCell>
              <TableCell align="right">No. Days</TableCell>
              <TableCell align="right">Start Date</TableCell>
              <TableCell align="right">End Date</TableCell>
              

              <TableCell align="right">Created On</TableCell>
              <TableCell align="right">Images</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {params?.row?.ctvisits?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ color: "darkyellow" }}
                >
                  {row.review}
                </TableCell>
                <TableCell align="right">{row.nol}</TableCell>
                <TableCell align="right">{row.nod}</TableCell>
                
                
                <TableCell align="right">
                  {new Intl.DateTimeFormat("en-GB", {
                    day: "2-digit",
                    year: "numeric",
                    month: "short",
              
                  }).format(new Date(row.sdate))}
                </TableCell>
                <TableCell align="right">
                  {new Intl.DateTimeFormat("en-GB", {
                    day: "2-digit",
                    year: "numeric",
                    month: "short",
                  
                  }).format(new Date(row.edate))}
                </TableCell>
                <TableCell align="right">
                  {new Intl.DateTimeFormat("en-GB", {
                    day: "2-digit",
                    year: "numeric",
                    month: "short",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true,
                  }).format(new Date(row.created))}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ color: "grren" }}
                >
                  {row?.ctimages?.map((r) => (
                    <Stack spacing={2} direction={"row"}>
                      <>
                        <a
                          color="green"
                          target="_blank"
                          href={`${BASE_URL}/ctvisits/image/${r.ctimageName}`}
                        >
                          View {r.id}
                        </a>
                        <img
                          src={`${BASE_URL}/ctvisits/image/${r.ctimageName}`}
                          alt="Avatar"
                          style={{ width: "50px", height: "50px" }}
                          key={r.id}
                        />
                      </>
                    </Stack>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </>
    );
  },
},

    // JE directions
    {
      field: "jvisits",
      headerName: "JE Site Visits",
      width: 500,
      renderCell: (params) => {
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 150 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", fontSize: "20" }}>
                    Reviews
                  </TableCell>
                  <TableCell align="right">Created On</TableCell>
                  <TableCell align="right">Images</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {params?.row?.jvisits?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: "darkyellow" }}
                    >
                      {row.remark}
                    </TableCell>
                    {/* <TableCell align="right">{row.createdBy}</TableCell> */}
                    <TableCell align="right">
                      {new Intl.DateTimeFormat("en-GB", {
                        day: "2-digit",
                        year: "numeric",
                        month: "short",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      }).format(new Date(row.created))}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: "grren" }}
                    >
                      {row?.jvimages?.map((r) => (
                        <Stack spacing={2} direction={"row"}>
                          <>
                            <a
                              color="green"
                              target="_blank"
                              href={`${BASE_URL}/jvisits/image/${r.jvimageName}`}
                            >
                              View {r.id}
                            </a>
                            <img
                              src={`${BASE_URL}/jvisits/image/${r.jvimageName}`}
                              alt="Avatar"
                              style={{ width: "50px", height: "50px" }}
                              key={r.id}
                            />
                          </>
                        </Stack>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      },
    },
    // XEN directions
    {
      field: "xvisits",
      headerName: "XEN Site Visits",
      width: 500,
      renderCell: (params) => {
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 150 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", fontSize: "20" }}>
                    Reviews
                  </TableCell>
                  <TableCell align="right">Created On</TableCell>
                  <TableCell align="right">Images</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {params?.row?.xvisits?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: "darkyellow" }}
                    >
                      {row.remark}
                    </TableCell>
                    {/* <TableCell align="right">{row.createdBy}</TableCell> */}
                    <TableCell align="right">
                      {new Intl.DateTimeFormat("en-GB", {
                        day: "2-digit",
                        year: "numeric",
                        month: "short",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      }).format(new Date(row.created))}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: "grren" }}
                    >
                      {row?.ximages.map((r) => (
                        <Stack spacing={2} direction={"row"}>
                          <>
                            <a
                              color="green"
                              target="_blank"
                              href={`${BASE_URL}/xvisits/image/${r.ximageName}`}
                            >
                              View {r.id}
                            </a>
                            <img
                              src={`${BASE_URL}/xvisits/image/${r.ximageName}`}
                              alt="Avatar"
                              style={{ width: "50px", height: "50px" }}
                              key={r.id}
                            />
                          </>
                        </Stack>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      },
    },
    // SE directions
    {
      field: "sevisits",
      headerName: "SE Site Visits",
      width: 500,
      renderCell: (params) => {
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 150 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", fontSize: "20" }}>
                    Reviews
                  </TableCell>
                  <TableCell align="right">Created On</TableCell>
                  <TableCell align="right">Images</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {params?.row?.sevisits?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: "orange" }}
                    >
                      {row.review}
                    </TableCell>
                    {/* <TableCell align="right">{row.createdBy}</TableCell> */}
                    <TableCell align="right">
                      {new Intl.DateTimeFormat("en-GB", {
                        day: "2-digit",
                        year: "numeric",
                        month: "short",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      }).format(new Date(row.created))}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: "grren" }}
                    >
                      {row?.simages.map((r) => (
                        <Stack spacing={2} direction={"row"}>
                          <>
                            <a
                              color="green"
                              target="_blank"
                              href={`${BASE_URL}/svisits/image/${r.simageName}`}
                            >
                              View {r.id}
                            </a>
                            <img
                              src={`${BASE_URL}/svisits/image/${r.simageName}`}
                              alt="Avatar"
                              style={{ width: "50px", height: "50px" }}
                              key={r.id}
                            />
                          </>
                        </Stack>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      },
    },
    // CE directions
    {
      field: "cevisits",
      headerName: "CE Site Visits",
      width: 500,
      renderCell: (params) => {
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 150 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", fontSize: "20" }}>
                    Reviews
                  </TableCell>
                  <TableCell align="right">Created On</TableCell>
                  <TableCell align="right">Images</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {params?.row?.cevisits?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: "orange" }}
                    >
                      {row.review}
                    </TableCell>
                    {/* <TableCell align="right">{row.createdBy}</TableCell> */}
                    <TableCell align="right">
                      {new Intl.DateTimeFormat("en-GB", {
                        day: "2-digit",
                        year: "numeric",
                        month: "short",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      }).format(new Date(row.created))}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: "grren" }}
                    >
                      {row?.cimages.map((r) => (
                        <Stack spacing={2} direction={"row"}>
                          <>
                            <a
                              color="green"
                              target="_blank"
                              href={`${BASE_URL}/cvisits/image/${r.cimageName}`}
                            >
                              View {r.id}
                            </a>
                            <img
                              src={`${BASE_URL}/cvisits/image/${r.cimageName}`}
                              alt="Avatar"
                              style={{ width: "50px", height: "50px" }}
                              key={r.id}
                            />
                          </>
                        </Stack>
                      ))}
                    </TableCell>

                    {/* <Card>
          {params?.row?.cevisits?.cimages?.map((image) => (
            
            <Stack spacing={2} direction={'row'}>
            <>
                <a
            color="green"
                target="_blank"
                href={`${BASE_URL}/api/cvisits/image/${image.cimageName}`}
              >
                View {image.id}
              </a>
              <img
                src={`${BASE_URL}/api/cvisits/image/${image.cimageName}`}
               
                alt="Avatar" style={{ width: '50px', height: '50px', }}
                key={image.id}
                
              />
              
            </>
            </Stack>
          ))}
        </Card> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      },
    },

    // CEO directions
    {
      field: "creviews",
      headerName: "CEO Directions",
      width: 500,
      renderCell: (params) => {
        return (
          <>
{params?.row?.creviews?.map((row) => ( 
<Card sx={{ maxWidth: 'auto' }}>
  
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            As On :{new Intl.DateTimeFormat("en-GB", {
                    day: "2-digit",
                    year: "numeric",
                    month: "short",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true,
                  }).format(new Date(row.created))}:
          </Typography>
          <Typography variant="h6" color="red" align="justify">{row.review}</Typography>
          <Typography variant="body2" align="right" sx={{ color: 'text.secondary' }}>
          Created By:{row.createdBy}
          </Typography>
        </CardContent>
        </Card>
                ))}

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 150 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", fontSize: "20" }}>
                    Directions
                  </TableCell>
                  <TableCell align="right">Created On</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {params?.row?.creviews?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: "red" }}
                    >
                      {row.review}
                    </TableCell>
                    {/* <TableCell align="right">{row.createdBy}</TableCell> */}
                    <TableCell align="right">
                      {new Intl.DateTimeFormat("en-GB", {
                        day: "2-digit",
                        year: "numeric",
                        month: "short",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      }).format(new Date(row.created))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </>
        );
      },
    },
  ];

  return (
  <>
      <Box height={70} />
   
    
        <Grid container spacing={1}> 
        <Grid size={12} >
          <DataGrid
            rows={works}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            getRowHeight={() => "auto"}
            // getRowSpacing={() => "auto"}
           
            //checkboxSelection
          />
                 </Grid>
     
   

      </Grid>
    
    </>
  );
};

export default ProjectList;
