import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from './api';
import newAxios from 'axios';

// Async actions for CRUD operations
export const fetchXvisits = createAsyncThunk('xvisits/fetchXvisits', async () => {
    const response = await axios.get(`api/xvisits`);
    return response.data;
});
export const fetchImages = createAsyncThunk('xvisits/fetchImages', async () => {
    const response = await axios.get(`api/xvisits/images`);
    return response.data;
});
const getCookie=(key)=>document.cookie.match('(^|;)\\s*'+key+'\\s*=\\s*([^;]+)')?.pop() || ''
const authToken = getCookie('jwt');

export const createXvisit = createAsyncThunk('xvisits/createXvisit', async (xvisitData) => {
    const response = await axios.post(`api/xvisits`, xvisitData, {
        headers: {
            'Content-Type': 'multipart/form-data',
             
        },
    });
    console.log(response.data);
    
    return response.data;
});

export const updateXvisit = createAsyncThunk('xvisits/updateXvisit', async ({ id, xvisitData }) => {
    const response = await axios.put(`api/xvisits/${id}`, xvisitData);
    return response.data;
});

export const deleteXvisit = createAsyncThunk('xvisits/deleteXvisit', async (id) => {
    await axios.delete(`api/xvisits/${id}`);
    return id;
});

const xvisitSlice = createSlice({
    name: 'xvisits',
    initialState: {
        xvisits: [],
        loading: false,
        error: null,
        ximages:[],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchXvisits.fulfilled, (state, action) => {
                state.xvisits = action.payload;
            })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.ximages = action.payload;
            })


          .addCase(createXvisit.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createXvisit.fulfilled, (state, action) => {
                state.loading = false;
                state.xvisits.push(action.payload);
            })
            .addCase(createXvisit.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })





            // .addCase(createXvisit.fulfilled, (state, action) => {
            //     state.xvisits.push(action.payload);
            // })








            .addCase(updateXvisit.fulfilled, (state, action) => {
                const index = state.xvisits.findIndex(user => user.id === action.payload.id);
                state.xvisits[index] = action.payload;
            })
            .addCase(deleteXvisit.fulfilled, (state, action) => {
                state.xvisits = state.xvisits.filter(user => user.id !== action.payload);
            });
    },
});

export default xvisitSlice.reducer;