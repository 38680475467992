import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

 import axios from './api';

// import { axios, API_BASE_URL, getCookie, jwt, setCookie} from './apiConfig';





// Thunks for async actions
export const register = createAsyncThunk('auth/register', async (userData, thunkAPI) => {
    try {
        const response = await axios.post(`api/auth/signup`, userData);
        const data = response.data;

        if (data.jwt) {
             localStorage.setItem('jwt', data.jwt);
            //setCookie("jwt",data.jwt,50);
        }

        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
    
});

export const login = createAsyncThunk('auth/login', async (userData, thunkAPI) => {
    try {
        const response = await axios.post(`api/auth/signin`, userData);
        const data = response.data;
         console.log(data);       

        if (data.jwt) {
            localStorage.setItem('jwt', data.jwt);
        //    setCookie("jwt",data.jwt,5);
           


        }
        thunkAPI.dispatch(getUser());
        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getUser = createAsyncThunk('auth/getUser', async (_, thunkAPI) => {
    try {
        const response = await axios.get(`api/users/profile`, 
        //     {
        //     headers: {
        //         Authorization: `Bearer ${localStorage.getItem('jwt')}`
        //          //Authorization: `Bearer ${getCookie('jwt')}`
        //      }
        // }
    );
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
    localStorage.clear();
    // setCookie("jwt","",0);
    return;
});

// Slice
const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        loading: false,
        error: null,
        jwt: null,
        projectSize: 0,
        isUserLoggedIn:false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.loading = false;
                state.jwt = action.payload.jwt;
            })
            .addCase(register.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.jwt = action.payload.jwt;
                state.isUserLoggedIn=true
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.isUserLoggedIn=false;
            })
            .addCase(getUser.pending, (state) => {
                state.loading = true;
                state.error = null;
                
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
                state.isUserLoggedIn=true;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.isUserLoggedIn=false
            })
            .addCase(logout.fulfilled, () => {
                return {
                    user: null,
                    loading: false,
                    error: null,
                    jwt: null,
                    projectSize: 0,
                    isUserLoggedIn:false
                };
            });
    }
});

export default authSlice.reducer;