import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePerformainvoice, fetchPerformainvoices } from '../../Redux/invoiceSlice';
import { IconButton, Stack, Typography } from '@mui/material';
import { EditNotifications } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { BASE_URL } from '../../Redux/apiConfig';
import PreviewIcon from '@mui/icons-material/Preview';
import { API_BASE_URL } from '../../Redux/api';
const InvoiceTable = ({ onEdit }) => {

  const dispatch = useDispatch();
  const performainvoices =useSelector((state)=>{
console.log(state.performainvoices.performainvoices);

   return state.performainvoices.performainvoices
});
  

   const perimages  = useSelector((state) => {
 
    
    return state.performainvoices.perimages;
   }
  );

  useEffect(() => {
    dispatch(fetchPerformainvoices());
   //const { performainvoices } = useSelector((state) => state.performainvoices.performainvoices);
   
    
  }, [dispatch]);

  const handleDelete = (perid) => {
    if (window.confirm("Are you sure you want to delete this Contractor work Info?")) {
      dispatch(deletePerformainvoice(perid));
    }
  };


  
  const columns = [
    { field: "perid", headerName: "ID", flex:1 },
    { field: "pino", headerName: "PI No.", flex:1 },
    { field: "dpi", headerName: "Date of PI", flex:1 },
    { field: "proposalno", headerName: "Proposal No.", flex:1 },
    { field: "efileno", headerName: "File No.", flex:1 },
    { field: "noresource", headerName: "No. of resources", flex:1 },
    { field: "estamt", headerName: "Estimate Amount", flex:1 },
    { field: "rdesig", headerName: "Resource Designation", flex:1 },
    { field: "rpost", headerName: "Resource Post", flex:1 },
    // { field: "rpost", headerName: "Advance Payment Quaterly", flex:1 },
    { field: "rtgsno", headerName: "RTGS No.", flex:1 },
    { field: "rtgsdate", headerName: "RTGS Date", flex:1 },
    { field: "noteno", headerName: "Note No.", flex:1 },
    { field: "taxInvoicefromHatron", headerName: "Tax Invoice From Hartron", flex:1 },

  
    { field: "createdBy", headerName: "Created By", flex: 1 },
    {
      field: "perimages",
      headerName: "Images",
      width: 500,
      height:300,
      
      renderCell: (params) => (
        <div style={{display:'flex'}}>
          {params?.row?.perimages?.map((image) => (
          
            <Stack spacing={2} direction={'row'}>
            <>
                <a
            color="green"
                target="_blank"
                href={`${API_BASE_URL}/performainvoices/image/${image.perimageName}`}
              >
                <PreviewIcon/> {image.id}
              </a>
              {/* <img
                src={`${BASE_URL}/api/performainvoices/image/${image?.perimageName}`}
               
                alt="Avatar" style={{ width: '50px', height: '50px', }}
                key={image.id}
                
              /> */}
              
            </>
            </Stack>
          ))}
        </div>
      ),
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 150,
    //   renderCell: (params) => (
    //     <IconButton onClick={() => handleDelete(params?.row?.id)}>
    //       Del...
     
    //     </IconButton>
    //   ),
    // },
    // {
    //     field: "Action",
    //     headerName: "Action",
    //     description: "This column has a value getter and is not sortable.",

    //     width: 60,
    //     renderCell: ({ row }) => (
    //       <IconButton onClick={() => onEdit(row)}>
    //         <EditNotifications />
    //       </IconButton>
    //     ),
    //   },
  ];


  return (
    <div>
       <DataGrid
          rows={performainvoices}
          columns={columns}
          getRowId={(row) => row.perid}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          // slots={{ toolbar: GridToolbar }}
   
        />
    </div>
  );
}

export default InvoiceTable;
