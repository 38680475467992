import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRdesig, updateRdesig } from '../../Redux/rdesigSlice'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Switch, FormControlLabel } from "@mui/material";

const RdesigForm = ({ open, handleClose, editData }) => {
    const dispatch = useDispatch();
    const { auth } = useSelector((store) => store);
    const [createdBy, setCreatedBy] = useState(`${auth.user.email}`);
    const [formData, setFormData] = useState({
        rdname: "",
        remark:"",
       
        active: true,
    });

    useEffect(() => {
        if (editData) {
            setFormData({
                rdname: editData.rdname || "",
                remark: editData.remark || "",
                createdBy: editData.createdBy || `${auth.user.email}`,
                active: editData.active || false,
            });
        } else {
            setFormData({ rdname: "", remark:"",createdBy: `${auth.user.email}`, active: true });
        }
    }, [editData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSwitchChange = (e) => {
        setFormData({ ...formData, active: e.target.checked });
    };

    const handleSubmit = () => {
        if (editData) {
            dispatch(updateRdesig({ id: editData.rid, data: formData }));
        } else {
            dispatch(addRdesig(formData));
        }
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{editData ? "Edit Rdesig" : "Add New Rdesig"}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Designation Name"
                    name="rdname"
                    value={formData.rdname}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Remarks"
                    name="remark"
                    value={formData.remark}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                {/* <TextField
                    label="Created By"
                    name="createdBy"
                    value={formData.createdBy}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                /> */}
                 <TextField
                                        // fullWidth
                                        // label="RTGS No."
                                        type="hidden"
                                        name="createdBy"
                                        // rows={2}
                                        value={createdBy}
                                         onChange={handleChange}
                                      />
                <FormControlLabel
                    control={<Switch checked={formData.active} onChange={handleSwitchChange} />}
                    label="Active"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    {editData ? "Update" : "Create"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RdesigForm;