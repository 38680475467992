import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './api';


// Thunks for file upload and download
export const uploadImage = createAsyncThunk('images/uploadImage', async ({selectedFile,meta}, thunkAPI) => {
    const formData = new FormData();   
    formData.append('title', meta.title);
    formData.append('description', meta.description);
    formData.append("file", selectedFile);   
 
   console.log(meta);
//console.log(formData);
try {
    const response = await axios.post(`api/v1/images`,formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    console.log(response.data);
    return response.data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
   
   
    
});

export const fetchImagesName = createAsyncThunk('files/fetchImagesName', async () => {
    const response = await axios.get(`api/v1/images/car.png`);
   //console.log(response);
    
    return response.data;
});

// export const uploadMultipleFiles = createAsyncThunk('files/uploadMultipleFiles', async (files) => {
//     const formData = new FormData();
//     files.forEach((file) => formData.append('files', file));

//     const response = await axios.post(`/api/files/uploadMultiple`, formData, {
//         headers: {
//             'Content-Type': 'multipart/form-data'
//         }
//     });
//     return response.data;
// });

export const fetchImages = createAsyncThunk('images/fetchImages', async () => {
    const response = await axios.get(`api/v1/images`);
    return response.data;
});

// export const fetchFilesName = createAsyncThunk('videos/fetchFilesName', async () => {
//     const response = await axios.get(`/api/videos/image/car.png`);
//     console.log(response);
    
//     return response.data;
// });

const imageSlice = createSlice({
    name: 'images',
    initialState: {
        images: [],
        status: 'idle',
        error: null,
       
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(uploadImage.fulfilled, (state, action) => {
                state.images.push(action.payload);
            })
            
            // .addCase(uploadMultipleFiles.fulfilled, (state, action) => {
            //     state.files = [...state.files, ...action.payload];
            // })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.images = action.payload;
            })
            // .addCase(fetchFilesName.fulfilled, (state, action) => {
            //     state.files1 = action.payload;
            //     console.log("hi");
                
            //     console.log(action.payload);
                
            // })
            ;
    }
});

export default imageSlice.reducer;