import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = 'http://localhost:8080/api/rdesigs';

export const fetchRdesigs = createAsyncThunk('rdesigs/fetch', async () => {
    const response = await axios.get(API_URL);
    return response.data;
});

export const addRdesig = createAsyncThunk('rdesigs/add', async (data) => {
    const response = await axios.post(API_URL, data);
    return response.data;
});

export const updateRdesig = createAsyncThunk('rdesigs/update', async ({ id, data }) => {
    const response = await axios.put(`${API_URL}/${id}`, data);
    return response.data;
});

export const deleteRdesig = createAsyncThunk('rdesigs/delete', async (id) => {
    await axios.delete(`${API_URL}/${id}`);
    return id;
});

const rdesigSlice = createSlice({
    name: 'rdesigs',
    initialState: { rdesigs: [], loading: false },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRdesigs.fulfilled, (state, action) => {
                state.rdesigs = action.payload;
            })
            .addCase(addRdesig.fulfilled, (state, action) => {
                state.rdesigs.push(action.payload);
            })
            .addCase(updateRdesig.fulfilled, (state, action) => {
                const index = state.rdesigs.findIndex(r => r.rid === action.payload.rid);
                if (index !== -1) state.rdesigs[index] = action.payload;
            })
            .addCase(deleteRdesig.fulfilled, (state, action) => {
                state.rdesigs = state.rdesigs.filter(r => r.rid !== action.payload);
            });
    }
});

export default rdesigSlice.reducer;