import { createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios  from './api';





export const fetchCworks = createAsyncThunk('cworks/fetchCworks', async (thunkAPI) => {
  // const response = await axios.get(`${BASE_URL}/api/cworks`);
  // return response.data;

  try {
    const response = await axios.get(`api/cworks`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const addCwork = createAsyncThunk('cworks/addCwork', async (cwork) => {
  const response = await axios.post(`api/cworks/cwork`,cwork);
  return response.data;

 // try {
    // const formData = new FormData();
    // formData.append('cwork',cwork);
   // formData.append('file', file);
   // const response = await axios.post(`${BASE_URL}/api/cworks`, cwork 
    //   {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   },
    // }
  // ) 
  //   .then(response => {
  //     console.log('Success:', response.data);
  // })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
    // const data = response.data;
    // return data;    

// } catch (error) {
//     return thunkAPI.rejectWithValue(error.message);
// }
});

export const updateCwork = createAsyncThunk('cworks/updateCwork', async ({ cworkId, cwork }) => {
  const response = await axios.put(`api/cworks/${cworkId}`, cwork);
  return response.data;
});

export const deleteCwork = createAsyncThunk('cworks/deleteCwork', async (cworkId) => {
  await axios.delete(`api/cworks/${cworkId}`);
  return cworkId;
}); 

export const changeCworkStatus = createAsyncThunk('cworks/changeCworkStatus', async ({ cworkId, active }) => {
  const response = await axios.patch(`api/cworks/${cworkId}/status?active=${active}`);
  return response.data;
});
const cworksSlice = createSlice({
  name: 'cworks',
  initialState: {
    cworks: [],
    status: 'idle',
    error: null,
    owner:null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCworks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCworks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cworks = action.payload;
       
      })
      .addCase(fetchCworks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addCwork.fulfilled, (state, action) => {
        state.cworks.push(action.payload);
      })
      .addCase(updateCwork.fulfilled, (state, action) => {
        const index = state.cworks.findIndex(cwork => cwork.cworkId === action.payload.cworkId);
        state.cworks[index] = action.payload;
      })
      .addCase(deleteCwork.fulfilled, (state, action) => {
        state.cworks = state.cworks.filter(cwork => cwork.cworkId !== action.payload);
      })

      .addCase(changeCworkStatus.fulfilled, (state, action) => {
        const index = state.cworks.findIndex((cwork) => cwork.cworkId === action.payload.cworkId);
        state.cworks[index] = action.payload;
    })
  },
});

export default cworksSlice.reducer;