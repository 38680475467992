import React from 'react';

import  { useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPersons } from '../../Redux/personSlice';
import { Grid2 as Grid, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import { API_BASE_URL } from '../../Redux/api';
//const API_URL = `http://api.erpfmda.in/projectms/api/auth/persons`;
//const API_URL = `http://localhost:8080/api/auth/persons`;

const PersonTable = () => {
    const dispatch = useDispatch();
    const  persons= useSelector((state) =>
        { return state.persons.persons});
    useEffect(() => {
        dispatch(fetchPersons());
    }, [dispatch]);

    const columns = [
        { field: 'id', headerName: 'ID', flex:1,},
        { field: 'pino', headerName: 'PI No.', flex:1},
        { field: 'pidate', headerName: 'PI Date', flex:1,
            valueFormatter:
        //  ({params}) => {
        // (params) => console.log(params.split('-').reverse().join('-'))
        (params) =>
          new Date(params).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "2-digit",
            year: "numeric",
          }
        ),
         },

        { field: 'proposalno', headerName: 'Proposal No.', flex:1},
        { field: 'dow', headerName: 'Work Date .', flex:1,
            valueFormatter:
            //  ({params}) => {
            // (params) => console.log(params.split('-').reverse().join('-'))
            (params) =>
              new Date(params).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "2-digit",
                year: "numeric",
              }
            ),
        },
        { field: 'efileno', headerName: 'eFile No.', flex:1},
        { field: 'noresource', headerName: 'No. of resources', flex:1},
        { field: 'estamt', headerName: 'Estimate Amount', flex:1},
        { field: 'rdesig', headerName: 'Resource Designation', flex:1},
        { field: 'advpaymentquaterlyAmt', headerName: 'Advance Payment Quarterly', flex:1},
        { field: 'rtgsno', headerName: 'RTGS No.', flex:1},
        { field: 'rtgsdate', headerName: 'RTGS Date ', flex:1,valueFormatter:
            //  ({params}) => {
            // (params) => console.log(params.split('-').reverse().join('-'))
            (params) =>
              new Date(params).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "2-digit",
                year: "numeric",
              }
            ),},
            { field: 'noteno', headerName: 'Note No.', flex:1},
            { field: 'taxInvoicefromHatron', headerName: 'Tax Invoice From Hartron', flex:1},
            { field: 'remark', headerName: 'Remarks', flex:1},

        { field: 'fileName', headerName: 'File Name', flex:1},
        { field: 'fileSize', headerName: 'Size (bytes)', flex:1},
        { field: 'uploadDate', headerName: 'Created Date', flex:1,
            valueFormatter:
            //  ({params}) => {
            // (params) => console.log(params.split('-').reverse().join('-'))
            (params) =>
              new Date(params)
                .toLocaleDateString("en-GB", {
                  // weekday: 'short',
    
                  day: "numeric",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                })
                .toUpperCase()
                .replaceAll(["/"], "-"),
         },
        { field: 'filePath', headerName: 'View PDF', flex:1,
            renderCell: (params) => (
            
                  <a href={`${API_BASE_URL}/auth/persons/${params.id}/download`} target='_blank'><PreviewIcon/></a>
             
                // <Link to={`${API_URL}/${params.id}/download`}><PreviewIcon/></Link>
              
                
            ),
         },
    ];

    return (
        <Grid container spacing={1}>
        <Grid item container direction="column" size={{xs:3,sm:4,md:6,lg:12}}  spacing={2}>
        <div style={{ height: 400, width:'100%' }}>
            <DataGrid rows={persons} columns={columns} 
             getRowId={(row) => row.id}
             initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            // loading={loading}
            slots={{ toolbar: GridToolbar }}
          getRowHeight={() => 'auto'}
             />
        </div>
        </Grid>
        </Grid>
    );
};

export default PersonTable;

