import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFiles, fetchFilesName } from '../../Redux/fileSlice';
import { Card, CardContent, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../Redux/apiConfig';


const FileList = () => {
    const dispatch = useDispatch();
    const files = useSelector((state) => state.files.files);
    const files1 = useSelector((state) => state.files.files1);
    const [images, setImages] = useState('');
   

    useEffect(() => {
        dispatch(fetchFiles());
       fetchFilesName();
    }, []);
   
    return (
<div>
    
    
        <List>
            {files.map((file) => (
                <ListItem key={file.id}>
                    <ListItemText primary={file.fileName} secondary={`${file.filePath}`} />
           <Link  target="_blank" to={`${BASE_URL}/files/image/${file.fileName}`} >View {file.id}</Link>
           <img key={file.id}  src={`${BASE_URL}/files/image/${file.fileName}`}  style={{ width: '200px', height: 'auto', margin: '10px' }}/>
                </ListItem>


            ))}
        </List>
        </div>
    );
};

export default FileList;