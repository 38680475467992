import { Box, Button, Grid2 as Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../../Redux/authSlice";
import { useDispatch } from "react-redux";
import m1 from './body_bg.jpg'
import { Send } from "@mui/icons-material";

const LoginForm = ({onClose}) => {
  const dispatch=useDispatch();

//   const [latitude,setLatitude]=useState('')
//   const [longitude,setLongitude]=useState('');
// useEffect(()=>{
// navigator.geolocation.getCurrentPosition((position)=>{
// setLatitude(position.coords.latitude);
// setLongitude(position.coords.longitude);
// })
// })
//  if((latitude!=null && latitude!="" && longitude!=null && longitude!="")){

  var handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      email: data.get("email"),
      password: data.get("password"),
    };
    
    dispatch(login(userData));
    onClose();
    // console.log("user data:", userData);
  };

// }else{
//   alert("select location");
// }
  
 
  const navigate = useNavigate();
  return (
    <> 
     <Grid container spacing={3}>
     <Grid item size={5} sx={{backgroundColor:'#f2b84b'}}>
      <Typography variant="h4" align="center" mt={5}>Work/Project Monitoring...</Typography>
      <Typography variant="h6" align="justify" m={4}>
      “we're delighted to have you on board. Please enter your credentials to log in and experience the convenience and work tracking..., we offer.”
      </Typography>
     </Grid>
     <Grid item size={7}>
    <div style={{backgroundImage:`url(${m1})`}} >
     {/* Latitude: {latitude}<br/>
      Longitude:{longitude} */}
      <Typography variant="h4"  color="primary" align={'center'}><b>Log In...</b></Typography>
      <Box height={20} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item size={12}>
            <TextField
              required
              id="email"
              name="email"
              label="User Id:"
              fullWidth
              autoComplete="email"
            />
          </Grid>
          <Grid item size={12}>
            <TextField
              required
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              autoComplete="password"
            />
          </Grid>
          <Grid item size={12}>
            <Button
              variant="contained"
              type="submit"
              size="medium"
              sx={{ padding: ".8rem 0", bgcolor: "#9155FD" }}
              className="bg-[#9155fd] w-full"
              
            >
              Log In <Send sx={{marginLeft:2}}/>
            </Button>
          </Grid>
        </Grid>
      </form>

      <div className="flex justify-center flex-col items-center">
        <div className="py-3 flex items-center">
          <p>If you don't have account?</p>
          <Button
            // onClick={() => navigate("/register")}
            className="ml-5"
            size="small"
          >
            Please contact Administrator!!
          </Button>
        </div>
      </div>
    </div>
     </Grid>
    </Grid>
    </>
  );
};

export default LoginForm;
