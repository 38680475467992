import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './api';

// Async actions for CRUD operations
export const fetchJvisits = createAsyncThunk('jvisits/fetchJvisits', async () => {
    const response = await axios.get(`api/jvisits`);
    return response.data;
});
export const fetchImages = createAsyncThunk('jvisits/fetchImages', async () => {
    const response = await axios.get(`api/jvisits/images`);
    return response.data;
});

export const createJvisit = createAsyncThunk('jvisits/createJvisit', async (jvisitData) => {
    const response = await axios.post(`api/jvisits`, jvisitData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
});

export const updateJvisit = createAsyncThunk('jvisits/updateJvisit', async ({ id, userData }) => {
    const response = await axios.put(`api/jvisits/${id}`, userData);
    return response.data;
});

export const deleteJvisit = createAsyncThunk('jvisits/deleteJvisit', async (id) => {
    await axios.delete(`api/jvisits/${id}`);
    return id;
});

const jvisitSlice = createSlice({
    name: 'jvisits',
    initialState: {
        jvisits: [],
        loading: false,
        error: null,
        jvimages:[],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchJvisits.fulfilled, (state, action) => {
                state.jvisits = action.payload;
            })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.jvimages = action.payload;
            })
            .addCase(createJvisit.fulfilled, (state, action) => {
                state.jvisits.push(action.payload);
            })
            .addCase(updateJvisit.fulfilled, (state, action) => {
                const index = state.jvisits.findIndex(user => user.id === action.payload.id);
                state.jvisits[index] = action.payload;
            })
            .addCase(deleteJvisit.fulfilled, (state, action) => {
                state.jvisits = state.jvisits.filter(user => user.id !== action.payload);
            });
    },
});

export default jvisitSlice.reducer;