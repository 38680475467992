import React, { useEffect, useState } from 'react'

import Highcharts, { chart, color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Highcharts3D from "highcharts/highcharts-3d";
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkCountByDivision } from '../../Redux/workReducer';
if (typeof Highcharts === "object") {
  Highcharts3D(Highcharts);
}

const BarChart = () => {



 
 
  const listWorkCountByDivision=useSelector((state) => state.works.workCountByDivision);

  const dispatch=useDispatch();
  const [chartData, setChartData] = useState([]);
  const [divs, setDivs] = useState([]);
  useEffect(()=>{
// dispatch(fetchWorksAllCountWithDivision());
dispatch(fetchWorkCountByDivision());
const divs1 = listWorkCountByDivision?.map((item) => [item.orguname]);
const chartData1 = listWorkCountByDivision?.map((item) => [item.totalWorks]);
setChartData(chartData1);
setDivs(divs1);
  },[dispatch])


  


  const options = {
    chart: {
      type: "column",
      options3d: {
        enabled: true,
        alpha: 10,
        beta: -45,
        depth:0,
        viewDistance:25
      },
    },
    plotOptions: {
      column: { depth: 65,shadow:true, size:'90%',
        dataLables:{
            enabled:false
        }
      },
    },
    title:{
text:'Divisions Projects ',
align:'center',
    },
    xAxis:{
categories:divs,
titile:"Divisions"
// ['Infra-I','Infra-II','Mobility','Electrical','Urban Planning','Urban Environment']
    },
    yAxis:{
title:{
  text:"No. of works",
    enabled:true
}
    },
    series: [
      {

        // name: "No. of Works",
        data: chartData
        // type: "column",
        // data: [
        //   { name: "Infra-I", y: 55,color:'#aaaaaa' },
        //   { name: "Infra-II", y: 45,color:'#fd1214' },
        //   { name: "Mobility", y: 15 ,color:'#fd12b4'},
        //   { name: "Electrical", y: 62 ,color:'#fdff14'},
        //   { name: "Urban Planning", y: 55 ,color:'#fd9614'},
        //   { name: "Urban Environment", y: 55 ,color:'#fa1554'},
        // ],
      },
    ],
  };
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};




export default BarChart