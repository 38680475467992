import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from './api';


export const fetchOrgtypes = createAsyncThunk('orgtypes/fetchOrgtypes', async (thunkAPI) => {
  // const response = await axios.get(`/api/orgtypes`);
  // return response.data;

  try {
    const response = await axios.get(`apis/orgtypes`);
    const data = response.data;
    // console.log(data);
    
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});


export const fetchOrgtypeIdName = createAsyncThunk('orgtypes/fetchOrgtypeIdName', async (thunkAPI) => {
  // const response = await axios.get(`/api/orgtypes`);
  // return response.data;

  try {
    const response = await axios.get(`api/getListIdName`);
    const data = response.data;
    // console.log(data);
    
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});


export const addOrgtype = createAsyncThunk('orgtypes/addOrgtype', async (orgtype,thunkAPI) => {
  // const response = await axios.post(`/api/orgtypes`, orgtype);
  // return response.data;

  try {
    const response = await axios.post(`api/orgtypes/`,orgtype);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const updateOrgtype = createAsyncThunk('orgtypes/updateOrgtype', async ({ orgtypeId, orgtype }) => {
  const response = await axios.put(`api/orgtypes/${orgtypeId}`, orgtype);
  return response.data;
});

export const deleteOrgtype = createAsyncThunk('orgtypes/deleteOrgtype', async (orgtypeId) => {
  await axios.delete(`api/orgtypes/${orgtypeId}`);
  return orgtypeId;
}); 

export const changeOrgtypeStatus = createAsyncThunk('orgtypes/changeOrgtypeStatus', async ({ orgtypeId, active }) => {
  const response = await axios.patch(`api/orgtypes/${orgtypeId}/status?active=${active}`);
  return response.data;
});

export const fetchActiveOrgtypes = createAsyncThunk('orgtypes/fetchActiveOrgtypes', async () => {
  const response = await axios.get(`api/orgtypes/count-aorgtypes`); 
  return response.data;
});
export const fetchInActiveOrgtypes = createAsyncThunk('orgtypes/fetchInActiveOrgtypes', async () => {
  const response = await axios.get(`api/orgtypes/count-inaorgtypes`);
  return response.data;
});



const orgtypesSlice = createSlice({
  name: 'orgtypes',
  initialState: {
    orgtypes: [],
    status: 'idle',
    error: null,
    owner:null,
    orgtypes1: [],
    orgtypes2: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrgtypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrgtypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orgtypes = action.payload;       
      })

      .addCase(fetchActiveOrgtypes.fulfilled, (state, action) => { 
        state.status = 'succeeded';
        state.orgtypes1 = action.payload;
      })
      .addCase(fetchInActiveOrgtypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orgtypes2 = action.payload;
      })

      .addCase(fetchOrgtypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchOrgtypeIdName.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrgtypeIdName.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orgtypes = action.payload;
       
      })
      .addCase(fetchOrgtypeIdName.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })



      .addCase(addOrgtype.fulfilled, (state, action) => {
        state.orgtypes.push(action.payload);
      })
      .addCase(updateOrgtype.fulfilled, (state, action) => {
        const index = state.orgtypes.findIndex(orgtype => orgtype.orgtypeId === action.payload.orgtypeId);
        state.orgtypes[index] = action.payload;
      })
      .addCase(deleteOrgtype.fulfilled, (state, action) => {
        state.orgtypes = state.orgtypes.filter(orgtype => orgtype.orgtypeId !== action.payload);
      })

      .addCase(changeOrgtypeStatus.fulfilled, (state, action) => {
        const index = state.orgtypes.findIndex((orgtype) => orgtype.orgtypeId === action.payload.orgtypeId);
        state.orgtypes[index] = action.payload;
    })
  },
});

export default orgtypesSlice.reducer;