import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRdesigs, deleteRdesig } from "../../Redux/rdesigSlice";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import RdesigForm from "./RdesigForm";

const Rdesigtable = () => {
    const dispatch = useDispatch();
    const { rdesigs } = useSelector(state => state.rdesigs);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(null);

    useEffect(() => {
        dispatch(fetchRdesigs());
    }, [dispatch]);

    const handleEdit = (rdesig) => {
        setEditData(rdesig);
        setOpen(true);
    };

  return (
    <>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Add Resource Designation
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Remarks</TableCell>
                        <TableCell>Created By</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rdesigs.map(r => (
                        <TableRow key={r.rid}>
                            <TableCell>{r.rid}</TableCell>
                            <TableCell>{r.rdname}</TableCell>                           
                            <TableCell>{r.remark}</TableCell>
                            <TableCell>{r.createdBy}</TableCell>
                            <TableCell>{r.active ? "Yes" : "No"}</TableCell>
                            <TableCell>
                                <Button onClick={() => handleEdit(r)}>Edit</Button>
                                <Button onClick={() => dispatch(deleteRdesig(r.rid))} color="error">
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <RdesigForm open={open} handleClose={() => setOpen(false)} editData={editData} />
        </>
  );
}

export default Rdesigtable;
