import React, { useEffect, useState } from "react";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Highcharts3D from "highcharts/highcharts-3d";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkCountByDivision, fetchWorksAllCountWithDivision } from "../../Redux/workReducer";
if (typeof Highcharts === "object") {
  Highcharts3D(Highcharts);
}

const PieChart = () => {

  // const worksListCountWithDiv=useSelector((state) => state.works.worksListCountWithDiv);
  const listWorkCountByDivision=useSelector((state) => state.works.workCountByDivision);

  const dispatch=useDispatch();
  const [chartData1, setChartData1] = useState([]);
  useEffect(()=>{
dispatch(fetchWorksAllCountWithDivision());
dispatch(fetchWorkCountByDivision());
// console.log("this is data"+listWorkCountByDivision);
setChartData1(listWorkCountByDivision);
  },[dispatch])


  const chartData = listWorkCountByDivision?.map((item) => [item.orguname,item.totalWorks
  ]);
  const options = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0,
      },
    },
    plotOptions: {
      pie: { depth: 65,shadow:true, size:'90%',
        dataLables:{
            enabled:true,
            format: "{item.orguname}: {item.totalWorks}", // Show category and value
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#fdfdfd" // Adjust color if needed
          }
        }
      },
    },
    title:{
text:'Divisions Projects ',
align:'center',
    },
    series: [
      {
        type: "pie",      
        name:'No. of Works',
        data:chartData
        // listWorkCountByDivision?.map((item) => ({
        //   id: item.id,
        //   value: item.orguname,
        //   label: item.totalWorks,
        // }))
        //  [
        //   { name: "Infra-I", y: 2,color:'#aaaaaa' },
        //   { name: "Infra-II", y: 22 },
        //   { name: "Mobility", y: 55 },
        //   { name: "Electrical", y: 62 },
        //   { name: "Urban Planning", y: 55 },
        //   { name: "Urban Environment", y: 55 },
        // ],
      },
    ],
    credits: {
      enabled: false  // ✅ This removes "Highcharts.com" watermark
    }
  };

  
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />

      {/* <PieChart
          width={600}
          height={300}
          series={[
            {
              data: chartData,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            },
          ]}
        /> */}


    </>
  );
};

export default PieChart;
