import React from 'react';
import ItadminDash from '../Itdiv/components/ItadminDash';
import { Route, Routes } from 'react-router-dom';
import Itadmin from '../Itdiv/components/Itadmin';
import Invoice from '../Itdiv/components/Invoice';
import Test from '../Itdiv/components/Test';
import Rdesig from '../Itdiv/components/Rdesig';

const ItadminRouter = () => {
  return (
    <div>
      <Routes>
            <Route path='/*' element={<Itadmin />}/>
            <Route path='/itadmindash' element={<ItadminDash />}/>
          <Route path='/invoice' element={<Invoice />}/>
          <Route path='/test' element={<Test />}/>
              <Route path='/rdesig' element={<Rdesig />}/>/
            {/* <Route path='/ctwork/:workId' element={<Ctvisit />}/> */}
            {/* <Route path='/video' element={<Video/>}/>
          
            <Route path='/imageup' element={<Image />}/> */}
           
        </Routes>
    </div>
  );
}

export default ItadminRouter;
