import React from 'react';
import RdesigForm from './RdesigForm';
import Rdesigtable from './Rdesigtable';
import Navbar from '../../Pages/components/Navbar';
import { Box, Typography } from '@mui/material';
import Sidenav from './Sidenav';

const Rdesig = () => {
  return (
    <div>
      <Navbar /> <Box height={70} />
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Typography variant="h3" align="center">
            {" "}
            Resource Designation
          </Typography>
          <hr />
          <RdesigForm/>
          <Rdesigtable />
         
        </Box>
      </Box>
    </div>
  );
}

export default Rdesig;
