import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from './api';

// import { BASE_URL, setCookie,axios } from './apiConfig';


export const fetchDesigs = createAsyncThunk('desigs/fetchDesigs', async (thunkAPI) => { 
  try {
    const response = await axios.get(`/desigs`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchDesigsIdName = createAsyncThunk('desigs/fetchDesigsIdName', async (thunkAPI) => { 
  try {
    const response = await axios.get(`api/desigs/getdesigidname`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const addDesig = createAsyncThunk('desigs/addDesig', async (desig,thunkAPI) => {
  // const response = await axios.post(`${BASE_URL}/api/desigs`, desig);
  // return response.data;
  try {
    const response = await axios.post(`api/desigs`,desig);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}

});

export const updateDesig = createAsyncThunk('desigs/updateDesig', async ({ id, desig,thunkAPI }) => {
  // const response = await axios.put(`/api/desigs/${id}`, desig);
  // return response.data;

  try {
    const response = await axios.put(`api/desigs/${id}`,desig);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const deleteDesig = createAsyncThunk('desigs/deleteDesig', async (id,thunkAPI) => {
  // await axios.delete(`/api/desigs/${id}`);
  // return id;

  try {
    axios.delete(`api/desigs/${id}`);
    // const data = response.data;
    return id;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}

  
}); 

export const changeDesigStatus = createAsyncThunk('desigs/changeDesigStatus', async ({ id, active,thunkAPI }) => {
  // const response = await axios.patch(`${BASE_URL}/api/desigs/${id}/status?active=${active}`);
  // return response.data;


  try {
    const response = await axios.patch(`/api/desigs/${id}/status?active=${active}`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const fetchActiveDesigs = createAsyncThunk('desigs/fetchActiveDesigs', async () => {
  const response = await axios.get(`/api/desigs/desigs-activecounts`); 
  return response.data;
});
export const fetchInActiveDesigs = createAsyncThunk('desigs/fetchInActiveDesigs', async () => {
  const response = await axios.get(`/api/desigs/desigs-inactivecounts`);
  return response.data;
});


const desigsSlice = createSlice({
  name: 'desigs',
  initialState: {
    desigs: [],
    status: 'idle',
    error: null,
    desigs1: [],
    desigs2: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

    .addCase(fetchActiveDesigs.fulfilled, (state, action) => { 
      state.status = 'succeeded';
      state.desigs1 = action.payload;
    })
    .addCase(fetchInActiveDesigs.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.desigs2 = action.payload;
    })
      .addCase(fetchDesigs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDesigs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.desigs = action.payload;
      })
      .addCase(fetchDesigsIdName.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.desigsIdName = action.payload;
      })
      .addCase(fetchDesigs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addDesig.fulfilled, (state, action) => {
        state.desigs.push(action.payload);
      })
      .addCase(updateDesig.fulfilled, (state, action) => {
        const index = state.desigs.findIndex(desig => desig.id === action.payload.id);
        state.desigs[index] = action.payload;
      })
      .addCase(deleteDesig.fulfilled, (state, action) => {
        state.desigs = state.desigs.filter(desig => desig.id !== action.payload);
      })

      .addCase(changeDesigStatus.fulfilled, (state, action) => {
        const index = state.desigs.findIndex((desig) => desig.id === action.payload.id);
        state.desigs[index] = action.payload;
    })
  },
});

export default desigsSlice.reducer;